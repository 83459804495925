<template>
  <div class="transaction">
    <b-row>
      <b-col cols="4">
        <p class="text-header">Event Setting</p>
      </b-col>
      <b-col cols="8">
        <div class="d-flex">
          <div class="g-form">
            <div class="input">
              <b-form-input
                v-model="filter.eventName"
                placeholder="Event Name"
                @focus="iconSearch = true"
                @blur="iconSearch = false"
                @keyup.enter="getEventList"
              ></b-form-input>
              <b-button
                class="search"
                :class="iconSearch ? 'active-border' : ''"
              >
                <b-icon
                  @click="getEventList"
                  stacked
                  icon="search"
                  scale="0.5"
                  variant="grey"
                ></b-icon>
              </b-button>
            </div>
          </div>

          <b-button class="btn-filter right ml-2" @click="openSideBar">
            <span class="d-none d-md-block">
              <font-awesome-icon icon="filter" class="pointer" />
              <span class="btn-text">Filter</span>
            </span>
          </b-button>
          <div class="mx-1"></div>
          <b-button class="btn-filter right create" @click="goCreateEvent">
            <span class="d-none d-md-block">
              <span>Create Event</span>
            </span>
          </b-button>
        </div>
      </b-col>
    </b-row>

    <SideBarFilter
      :filter="filter"
      ref="sidebarFilter"
      placeholder="Transaction"
      @searchAll="searchAll"
      @clearFilter="clearFilter"
      :hideStatusFilter="false"
      :hideSearchBar="true"
    >
      <template v-slot:filter-option>
        <div class="mt-3 mb-3">
          <InputText
            class="my-2 login-input"
            v-model="filter.eventName"
            textFloat="Search"
            placeholder="Event name"
            type="text"
            name="text"
            isRequired
          />
          <InputSelect
            v-model="filter.previewOrPublish"
            :options="optionsStatus"
            title="Status"
            class="mt-2"
            valueField="value"
            textField="text"
          />
          <!-- <div class="publish-event">
            <b-form-checkbox
              v-model="filter.previewOrPublish"
              switch
              :value="1"
              :unchecked-value="null"
            >
              Publist Event
            </b-form-checkbox>
          </div> -->
        </div>
      </template>
    </SideBarFilter>

    <Table
      :items="items"
      :fields="fields"
      :isBusy="isBusy"
      :rows="rows"
      :filter="filter"
      :pageOptions="pageOptions"
      @filterPage="filterPage"
    />

    <!-- <ModalConfirmPayment
      :dataPayment="dataPayment"
      @confirmSuccess="confirmSuccess"
    /> -->
  </div>
</template>

<script>
import Table from "./component/Table.vue";
// import ModalConfirmPayment from "@/components/modal/ModalConfirmPayment.vue";

import { required, requiredIf } from "vuelidate/lib/validators";
export default {
  components: {
    Table,
    // ModalConfirmPayment,
  },
  data() {
    return {
      // state
      filter: {
        eventName: null,
        previewOrPublish: null,
        skip: 1,
        take: 10,
        page: 1,
      },

      // options filter
      optionsStatus: [
        {
          value: null,
          text: "All",
        },
        {
          value: 0,
          text: "Preview",
        },
        {
          value: 1,
          text: "Publish",
        },
      ],

      // state modal
      dataPayment: null,

      // state table
      items: [],
      fields: [
        {
          key: "eventId",
          label: "Event Id",
          class: "w-100px text-nowrap",
        },
        {
          key: "name",
          label: "Name",
          class: "w-100px text-nowrap",
        },
        {
          key: "previewOrPublish",
          label: "Status",
          class: "w-100px text-nowrap",
        },
        { key: "actions", label: "Action" },
      ],
      isBusy: false,
      rows: 0,
      pageOptions: [
        { value: 10, text: "10 / page" },
        { value: 30, text: "30 / page" },
        { value: 50, text: "50 / page" },
        { value: 100, text: "100 / page" },
      ],

      // state sidebar
      styleDatetime: {
        width: "100%",
        border: "none",
      },

      // state css
      iconSearch: false,
    };
  },

  mounted() {
    this.getEventList();

    // condition date
    // this.maxDate = new Date().toISOString();
  },
  methods: {
    async getEventList() {
      this.isBusy = true;

      const getData = await this.$services.event.getEventList(this.filter);

      if (getData.result == 1) {
        this.items = getData.detail.data;
        this.rows = getData.detail.count;
      } else {
        this.$cookies.remove("back_office_admin_sale_order_token");
        this.$router.replace("/");
      }

      this.isBusy = false;
    },

    // confirmSuccess() {
    //   // this.getEventList();
    // },
    filterPage(obj) {
      this.filter.skip = obj.page;
      this.getEventList();
    },
    openSideBar() {
      this.$refs.sidebarFilter.show();
    },
    goCreateEvent() {
      this.$router.push("/createevent");
    },
    searchAll(filter) {
      let { eventName } = filter;

      if (!eventName) {
        this.filter.eventName = null;
      }

      this.getEventList();
      this.closeSidebarFilter();
      return;
    },
    clearFilter() {
      this.filter = {
        eventName: null,
        previewOrPublish: null,
        skip: 1,
        take: 10,
        page: 1,
      };

      this.getEventList();
      this.closeSidebarFilter();
    },
    closeSidebarFilter() {
      this.$refs.sidebarFilter.hide();
    },
  },
};
</script>

<style lang="scss" scoped>
.transaction {
  .text-header {
    color: #092d53;
    font-weight: 600;
    font-size: 20px;
    text-transform: uppercase;
  }
}
.text-error {
  margin: 0;
  color: red;
}
// ! override
.g-form {
  width: 100%;
}

.btn-filter {
  &.right {
    float: right;
  }

  &.create {
    min-width: 110px !important;
  }
}

::v-deep .btn-filter button,
.btn-filter {
  color: var(--primary-color) !important;
  border-color: var(--primary-color);
  font-weight: 100;
  min-width: 80px;
}
</style>
